<div class="flex flex-row items-center justify-start">
  <div
    class="tr-group mat-bg-card flex flex-row items-center justify-start rounded-full px-1.5 py-1"
    #parentRef
    *ngIf="this.range$ | async as range"
  >
    <button mat-icon-button fxHide.lt-md class="tr-left-btn" [disabled]="disabled" (click)="this.goBack(range)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-button
      class="tr-mid-btn"
      (click)="this.openRangePicker(parentRef)"
      [style.width.px]="fixedMidButtonWidth"
      [disabled]="disabled"
      [matTooltip]="'utils.pickTimeRange' | translate"
      [matTooltipDisabled]="disabled"
    >
      <div class="flex flex-row items-center justify-start">
        <mat-icon inline fxHide.gt-sm class="mr-2 text-lg">calendar_today</mat-icon>
        <ng-container
          *ngTemplateOutlet="
            (this.range$SameDay | async)
              ? sameDayTemplate
              : (this.range$SameYear | async)
                ? sameYearTemplate
                : defaultDayViewTemplate
          "
        ></ng-container>
        <ng-template #sameDayTemplate>
          {{ range.from | formatDate: 'PP' }}
        </ng-template>
        <ng-template #sameYearTemplate
          >{{ range.from | dfnsFormat: 'do LLL' }} - {{ range.to | dfnsFormat: 'do LLL' }}</ng-template
        >
        <ng-template #defaultDayViewTemplate>
          {{ range.from | formatDate: 'PP' }} -
          {{ range.to | formatDate: 'PP' }}
        </ng-template>
      </div>
    </button>
    <button mat-icon-button fxHide.lt-md class="tr-right-btn" [disabled]="disabled" (click)="this.goForward(range)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <mat-menu #changeRangePresetMenu="matMenu" [overlapTrigger]="true">
    <p class="px-5">{{ 'time-range.choose-timeframe' | translate }}</p>
    <mat-divider class="mb-2"></mat-divider>
    <div class="flex flex-col gap-1">
      <ng-container *ngIf="this.defaultRanges | async as dranges">
        <ng-container *ngFor="let r of dranges | iterateObject">
          <ng-container *ngIf="(this.range$ | async)?.name === r.name; else nonActive">
            <button
              mat-menu-item
              [disabled]="true"
              class="bg-primary-2"
              (click)="$event.stopPropagation(); $event.preventDefault()"
            >
              {{ r.name | translate }}
            </button>
          </ng-container>
          <ng-template #nonActive>
            <button mat-menu-item [disabled]="disabled" (click)="this.selectRange(r)">
              {{ r.name | translate }}
            </button>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </mat-menu>
</div>
